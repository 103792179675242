import Moment from "moment";

export const mmFormatStr = "YYYY-MM-DD HH:mm";

export function tsFormat(ts) {
  return Moment(ts).format("YYYY-MM-DD HH:mm:ss");
}
export function hoursFormat(ts) {
  return Moment(ts).format("YYYY-MM-DD HH:mm");
}

export function mmFormat(ts) {
  return Moment(ts).format(mmFormatStr);
}

export function dtFormat(dt) {
  return Moment(dt).format("YYYY-MM-DD");
}
